import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logoTitle from './images/logo-title.png'
import './Navbar.css';
import mobileLogo from './images/logo-mobile.png';

function Navbar() {
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
    const [dropdown, setDropdown] = useState(false);
    const closeMenu = () => {
        setClick(false);
        setDropdown(false); 
    };
    const [sticky, setSticky] = useState(false);
    const handleScroll = () => {
        if (window.scrollY > 0) {
            setSticky(true);
        } else {
            setSticky(false);
        }
    };
    const toggleDropdown = () => {
        setDropdown(!dropdown); // Toggle dropdown on Car Finder hover/click
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <header>
            <div className={`navbar-container ${sticky ? 'sticky' : ''}`}>
                <div className="logo-container">
                    <Link to='/' className="navbar-logo" onClick={closeMenu}>
                        <img id='logo-icon' src={mobileLogo} alt="Logo" />
                        <img id= 'logo-title' src={logoTitle} />
                    </Link>
                </div>
                <div className="menu-container">
                    <div className='menu-icon' onClick={handleClick}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                    </div>
                    <ul className={click ? "nav-menu active" : "nav-menu"}>
                        <li className='nav-item'>
                            <Link to='/' className='nav-links' onClick={closeMenu}>Home</Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/inventory' className='nav-links' onClick={closeMenu}>Inventory</Link>
                        </li>
                        <li className='nav-item'>
                            <div 
                                className='nav-links'
                                onClick={(e) => {
                                e.preventDefault();  
                                setDropdown(!dropdown);  
                                }}
                                style={{ cursor: 'pointer' }}  
                            >
                                Car Finder <i className="fas fa-caret-down" />
                            </div>
                            {dropdown && (
                                <ul className="dropdown-menu">
                                <li className="dropdown-item">
                                    <Link to='/requestCar' className='dropdown-link' onClick={closeMenu}>Request a Car</Link>
                                </li>
                                <li className="dropdown-item">
                                    <Link to='/sellCar' className='dropdown-link' onClick={closeMenu}>Sell Me Your Car</Link>
                                </li>
                                </ul>
                            )}
                        </li>
                        <li className='nav-item'>
                            <Link to='/about' className='nav-links' onClick={closeMenu}>About Us</Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/contact' className='nav-links' onClick={closeMenu}>Contact Us</Link>
                        </li>
                        
                    </ul>
                </div>
            </div>
        </header>
    );
}

export default Navbar;
