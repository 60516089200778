import React, { useEffect } from 'react';
import Intro from '../Intro.js';
import ShowRoom from '../ShowRoom.js'
import './home.css'
import shopPic from '../images/cars.png'
import { CarOutlined } from '@ant-design/icons'
import { TbMoneybag } from "react-icons/tb";
import { FaRegHandshake } from "react-icons/fa";
import ferrari from '../images/ferrari.jpg'
import gt3rs from '../images/gt3rs.jpg'
import { useNavigate } from 'react-router-dom';
function Home(){
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0); 
    }, []);
    const handleLearnMoreClick = () => {
        navigate('/sellCar'); 
    };
    return(
        <div className='home-container'>
            <div className="intro-container">
                <Intro />  
            </div>
            <div className='adBox-container'>
                <h1 className="adBox-title">HOW EASY IS BUYING A CAR FROM US?</h1>
                <div className="adBox-grid">
                    <div className="adBox-item"><CarOutlined style={{ fontSize: '40px', color: 'white' }}/><h>Search Our Inventory</h>Explore our extensive Variety Inventory, featuring a diverse range of top-quality vehicles to suit every need and budget. Whether you're looking for luxury, performance, or reliability, we have the perfect car for you!</div>
                    <div className="adBox-item"><TbMoneybag style={{ fontSize: '40px', color: 'white' }}/><h>No Hidden Fees</h>We guarante no hidden fees and no tricks. The prices listed on our website are the real prices, so you can shop with confidence knowing exactly what to expect.</div>
                    <div className="adBox-item"><FaRegHandshake style={{ fontSize: '40px', color: 'white' }}/><h>Easy To Trade</h>Trading in your car has never been easier! We offer competitive trade-in values, so you can seamlessly upgrade to the car you’ve been dreaming of. Let us handle the trade while you enjoy the benefits of driving something new</div>
                </div>
            </div>
            <div className='description-container'>
                <div className='description-left'>
                    <img id='frontShop' src={shopPic} alt="Shop Front" />
                </div>
                <div className='description-right'>
                    <h2>CommonWealth Motor</h2>
                    <p>
                        Welcome to our dealership! We pride ourselves on providing an exceptional 
                        car-buying experience with a range of luxury and high-quality vehicles.
                    </p>
                </div>
            </div>
            <ShowRoom />

            <div className='learnMore-container'>
                <h1>WE DON'T JUST SELL CARS</h1>
                <h1>WE'RE HERE TO BUY YOURS TOO!</h1>
                <p>
                    At Commonwealth Motor, we strive not only to help our customers find the perfect car but also to give their current vehicles a great new home.
                </p>
                <p>
                    Our car-buying prices are often higher than Carfax, and if you're in the market for a
                    new car, we're happy to assist you with that too!
                </p>
                <div className='learnMore-image-container'>
                    <img id='porshe' src={ gt3rs } />
                    <img id='ferrari' src={ ferrari } />
                </div>
                <h2>Your Car, Our Priority – Whether You're Buying or Selling!</h2>
                <button className="learnMore-btn" onClick={handleLearnMoreClick}>
                    <span>LEARN MORE</span>
                </button>
            </div>
        </div>
    );
}

export default Home