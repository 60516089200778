import React, { useEffect } from 'react';
import './AboutUs.css';
import lamboImage from '../images/Lambo.jpg'; 
import serviceImage from '../images/service.jpg';
import tireImage from '../images/tires.jpg'


const AboutUs = () =>{
    const address = "4501 Stelton Rd, South Plainfield, NJ 07080";
    const handleButtonClick = () => {
        const encodedAddress = encodeURIComponent(address);
        const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodedAddress}`;
        window.open(googleMapsUrl, '_blank');
      };
    useEffect(() => {
        window.scrollTo(0, 0); 
    }, []);
    return(
        <body>
            <div className='about-title-container'>
                <div className='about-title'>
                    About Us
                </div>
            </div>
            <div className="service-container">
                <div className="column">
                    <section className="about-section">
                        <h2>About Commonwealth Motor</h2>
                        <p>
                            Commonwealth Motor isn't just a dealership; it's a gateway to the world of fine cars.
                            With over eight years of excellence, we specialize in offering premium vehicles that
                            embody quality and sophistication. Based in New Jersey, we serve a diverse clientele
                            from New York, Pennsylvania, Boston, and Delaware. Family-owned and driven by passion,
                            our slogan, 'We bring fine cars,' reflects our commitment to providing luxury car
                            enthusiasts with vehicles that are not just modes of transportation, but symbols of
                            lifestyle and status.
                        </p>
                    </section>
                    <section className="location-section">
                        <h3>Where We Are</h3>
                        <p>4501 Stelton Rd, South Plainfield, NJ 07080</p>
                        <button>
                        <span class="button_top" onClick={handleButtonClick}> Get Direction</span></button>
                    </section>
                    <section className="image-section">
                        <img src={lamboImage} alt="Lamborghini at Commonwealth Motor" className="car-image" />
                        <img src={tireImage} alt="Tires at Commonwealth Motor" className="car-image" />
                    </section>
                </div>
                <div className="column">
                    <section className="image-section">
                        <img src={serviceImage} alt="Service Center at Commonwealth Motor" className="car-image" />
                    </section>
                    <section className="social-media-section">
                        <h3>
                        Follow Us On Social Media To Get More Recent Updates On Our Inventory!
                        </h3>
                        <div className="social-media-buttons">
                            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" className="social-media-button twitter">
                                <i className="fab fa-tiktok"></i> 
                            </a>
                            <a href="https://www.instagram.com/commonwealthmotornj/" target="_blank" rel="noopener noreferrer" className="social-media-button instagram">
                                <i className="fab fa-instagram"></i> 
                            </a>
                        </div>
                    </section>
                </div>
            </div>
        </body>
    )
}
export default AboutUs;