import React, { useState, useRef , useEffect} from 'react';
import emailjs from "emailjs-com"
import './RequestCar.css'
const RequestCar = () => {
    const form = useRef();
    const [formData, setFormData] = useState({
        make: '',
        model: '',
        year: '',
        mileage: '',
        priceRange: '',
        features: '',
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        comments: ''
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const validate = () => {
        let tempErrors = {};
        if (!formData.firstName) tempErrors.firstName = "First name is required";
        if (!formData.lastName) tempErrors.lastName = "Last name is required";
        if (!formData.email) tempErrors.email = "Email is required";
        if (!formData.phone) tempErrors.phone = "Phone number is required";
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0; // Return true if no errors
    };

    const sendEmail = (e) => {
        e.preventDefault();
        
        // Validate the form
        if (validate()) {
            // Send email if valid
            emailjs
            .sendForm('service_h3c12et', 'template_45w7yyb', form.current, 'Jxp06y9U5VmEzGA9m')
            .then(
                () => {
                    console.log('SUCCESS!');
                    // Reset formData to clear form fields
                    setFormData({
                        make: '',
                        model: '',
                        year: '',
                        mileage: '',
                        priceRange: '',
                        features: '',
                        firstName: '',
                        lastName: '',
                        phone: '',
                        email: '',
                        comments: ''
                    });
                    e.target.reset(); 
                },
                (error) => {
                    console.log('FAILED...', error.text);
                }
            );
        }
    };
    useEffect(() => {
        window.scrollTo(0, 0); 
    }, []);
    return(
        <body>
            <div className='finder-title-container'>
                <div className='finder-title'>
                    CAR FINDER
                </div>
            </div>
            <div className='car-finder-container'>
                <h2 className='car-finder-title'>
                    Let us know exactly what you’re in the market for and we’ll help you find it.
                </h2>
                <form ref={form} onSubmit={sendEmail} className="form-section">
                    <div className="form-row">
                        <div className="form-group">
                            <label>Make *</label>
                            <input type="text" name="make" value={formData.make} onChange={handleChange} required />
                        </div>
                        <div className="form-group">
                            <label>Model *</label>
                            <input type="text" name="model" value={formData.model} onChange={handleChange} required />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group">
                            <label>Year *</label>
                            <input type="text" name="year" value={formData.year} onChange={handleChange} required />
                        </div>
                        <div className="form-group">
                            <label>Max Mileage *</label>
                            <input type="text" name="mileage" value={formData.mileage} onChange={handleChange} required />
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Price Range</label>
                        <select name="priceRange" value={formData.priceRange} onChange={handleChange}>
                            <option>Price Range</option>
                            <option value="5,000 - 10,000">$5,000 - $10,000</option>
                            <option value="10,000 - 15,000">$10,000 - $15,000</option>
                            <option value="15,000 - 20,000">$15,000 - $20,000</option>
                            <option value="20,000 - 25,000">$20,000 - $25,000</option>
                            <option value="25,000 - 30,000">$25,000 - $30,000</option>
                            <option value="30,000 - 35,000">$30,000 - $35,000</option>
                            <option value="35,000 - 40,000">$35,000 - $40,000</option>
                            <option value="40,000 - 45,000">$40,000 - $45,000</option>
                            <option value="45,000 - 50,000">$45,000 - $50,000</option>
                            <option value="50,000 - 55,000">$50,000 - $55,000</option>
                            <option value="55,000 - 60,000">$55,000 - $60,000</option>
                            <option value="60,000 - 65,000">$60,000 - $65,000</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label>Desired Features</label>
                        <textarea name="features" value={formData.features} onChange={handleChange} />
                    </div>

                    <h3>Contact Information</h3>
                    <div className="form-row">
                        <div className="form-group">
                            <label>First Name *</label>
                            <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} required />
                            {errors.firstName && <p className="error">{errors.firstName}</p>}
                        </div>
                        <div className="form-group">
                            <label>Last Name *</label>
                            <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} required />
                            {errors.lastName && <p className="error">{errors.lastName}</p>}
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group">
                            <label>Phone Number *</label>
                            <input type="tel" name="phone" value={formData.phone} onChange={handleChange} required />
                            {errors.phone && <p className="error">{errors.phone}</p>}
                        </div>
                        <div className="form-group">
                            <label>Email *</label>
                            <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                            {errors.email && <p className="error">{errors.email}</p>}
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Comments</label>
                        <textarea name="comments" value={formData.comments} onChange={handleChange}></textarea>
                    </div>
                    <button type="submit" className="submit-btn">Submit</button>
                </form>
            </div>
        </body>
    )
}
export default RequestCar;