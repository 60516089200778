import React, { useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import './ImageCarousel.css';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const ImageCarousel = ({ images = [] }) => {
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const galleryImages = images.map((image) => ({
    original: image,
    thumbnail: image,  // You can use separate thumbnail URLs if available
  }));

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setIsGalleryOpen(true);
  };

  const closeGallery = () => {
    setIsGalleryOpen(false);
  };

  return (
    <>
      <Carousel
        swipeable={true}
        draggable={false}
        responsive={responsive}
        ssr={true}
        infinite={true}
        keyBoardControl={false}
        containerClass="carousel-container-full-width"
        removeArrowOnDeviceType={['tablet', 'mobile']}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-wrapper" 
      >
        {images.map((image, index) => (
          <div key={index} onClick={() => handleImageClick(index)}>
            <img
              className="d-block w-100"
              src={image}
              alt={`Car image ${index + 1}`}
              style={{ cursor: 'pointer' }}
            />
          </div>
        ))}
      </Carousel>

      {/* React Image Gallery for fullscreen mode */}
      {isGalleryOpen && galleryImages.length > 0 && (
        <div className="fullscreen-gallery">
          <ImageGallery
            items={galleryImages}
            startIndex={currentImageIndex}
            showThumbnails={true}
            showFullscreenButton={true}
            showPlayButton={false}
            showNav={true}
            onClick={() => closeGallery()}
            useBrowserFullscreen={true}
            showBullets={true}
          />
        </div>
      )}
    </>
  );
};

export default ImageCarousel;
