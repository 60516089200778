import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaGripLinesVertical, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import './ShowRoom.css';

const ShowRoom = () => {
    const [cars, setCars] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const carouselRef = useRef(null);

    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        fetchCars();
    }, []);

    const fetchCars = async () => {
        try {
            const response = await axios.get(`${API_URL}/cars/`);
            const allCars = response.data;
            const randomCars = allCars.sort(() => 0.5 - Math.random()).slice(0, 6);
            setCars(randomCars);
        } catch (error) {
            console.error("Error fetching cars:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleCardClick = (id) => {
        navigate(`/car/${id}`);
    };

    const handleButtonClick = () => {
        navigate('/inventory'); 
    };

    const scrollLeft = () => {
        carouselRef.current.scrollBy({ left: -300, behavior: 'smooth' });
    };

    const scrollRight = () => {
        carouselRef.current.scrollBy({ left: 300, behavior: 'smooth' });
    };

    return (
        <div className='showroom-container'>
            <div className='showroom-title'>
                <h1>Check Out Our Inventory</h1>
            </div>

            <div className="carousel-wrapper">
                <button className="scroll-button" onClick={scrollLeft}>
                    <FaArrowLeft />
                </button>

                <div className="showroom-content" ref={carouselRef}>
                    {loading && <div>Loading...</div>}
                    {!loading && cars.length === 0 && <div>No cars available</div>}
                    {!loading && cars.length > 0 && cars.map((car) => (
                        <div className="showroomCard" 
                            key={car.id}
                            onClick={() => handleCardClick(car.id)}
                            style={{ cursor: 'pointer' }}
                        >
                            <img className="showroom-Profile-Pic" src={car.profile_pic} alt={`${car.brand} ${car.model}`} />
                            <div className="showroom-card-body">
                                <div className='icon-container'>
                                    <FaGripLinesVertical className="icon-style"/>
                                </div>
                                <div className='title-container'>
                                    <h5 className="showroom-card-title">{car.year} {car.brand} {car.model}</h5>
                                    <p className="showroom-card-price">${car.price}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <button className="scroll-button" onClick={scrollRight}>
                    <FaArrowRight />
                </button>
            </div>
            <button class="btn-17" onClick={ handleButtonClick }>
                <span class="text-container">
                    <span class="text">Our Inventory</span>
                </span>
            </button>

        </div>
    );
};

export default ShowRoom;
