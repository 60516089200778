import React from 'react';

function GoogleMapsLink({ address }) {
  const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;

  return (
    <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer">
      {address}
    </a>
  );
}

export default GoogleMapsLink;
