import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ImageCarousel from '../ImageCarousel';
import ContactBox from '../contactBox';
import './carDetails.css';
import '@fontsource/inter';
import Accordion from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionGroup from '@mui/joy/AccordionGroup';
import AccordionSummary from '@mui/joy/AccordionSummary';
import { IoMdBarcode } from "react-icons/io";
import { BsSpeedometer } from "react-icons/bs";
import { IoLogoModelS } from "react-icons/io";
import { MdOutlineAttachMoney } from "react-icons/md";
import { RiPaintLine } from "react-icons/ri";
import { GiCarSeat } from "react-icons/gi";
import { GiGearStickPattern } from "react-icons/gi";
import { FaCarSide } from "react-icons/fa";
import { FaArrowAltCircleDown } from "react-icons/fa";
import { AiOutlinePercentage } from "react-icons/ai";
import { SlCalender } from "react-icons/sl";
import carfax from '../images/carfax.jpg'
import { IoReturnUpBack } from "react-icons/io5";
import {  FaGripLinesVertical, FaArrowLeft, FaArrowRight } from "react-icons/fa";

const CarDetails = () => {
  const { id } = useParams();
  const [car, setCar] = useState(null);
  const [loading, setLoading] = useState(true);
  const [downPayment, setDownPayment] = useState();
  const [apr, setApr] = useState(6);
  const [term, setTerm] = useState(24); 
  const [monthlyPayment, setMonthlyPayment] = useState(null);
  const [similarCars, setSimilarCars] = useState([]);
  const carouselRef = useRef(null);
  const navigate = useNavigate();
  
  const API_URL = process.env.REACT_APP_API_URL;
  const fetchSimilarCars = (price) => {
    axios.get(`${API_URL}/cars/`)
      .then(response => {
        const filteredCars = response.data.filter(car => {
          const carPrice = car.price;
          return carPrice >= (price - 5000) && carPrice <= (price + 5000) && car.id !== id;
        });
        setSimilarCars(filteredCars);
      })
      .catch(error => {
        console.error('Error fetching similar cars:', error);
      });
  };
  const scrollLeft = () => {
    carouselRef.current.scrollBy({ left: -300, behavior: 'smooth' });
  };

  const scrollRight = () => {
    carouselRef.current.scrollBy({ left: 300, behavior: 'smooth' });
  };
  const handleCardClick = (id) => {
    navigate(`/car/${id}`);
  };
  useEffect(() => {
    axios.get(`${API_URL}/cars/${id}/`)
      .then(response => {
        setCar(response.data);
        setDownPayment(response.data.price * 0.3);
        fetchSimilarCars(response.data.price);
        setLoading(false);
      })
      .catch(error => {
        console.error('There was an error fetching the car details!', error);
        setLoading(false);
      });
      window.scrollTo(0, 0); 
  }, [id, API_URL]);
  const calculateMonthlyPayment = () => {
    const loanAmount = car.price - downPayment;
    const monthlyRate = apr / 100 / 12;
    const monthlyTerm = term;

    // Loan amortization formula
    const payment = loanAmount * monthlyRate / (1 - Math.pow((1 + monthlyRate), -monthlyTerm));
    setMonthlyPayment(payment.toFixed(2));
  };
  if (loading) {
    return <div>Loading...</div>;
  }

  if (!car) {
    return <div>No car details available</div>;
  }

  const carVin = car.vin;
  const images = [car.profile_pic, ...car.photo_urls];
  const carTitle = `${car.year} ${car.brand} ${car.model}`;
  const defaultSubject = `Interested in ${carTitle}`;
  const defaultMessage = `I would like more information about the ${carTitle}.`;

  


  return (
    <div className="car-details-page">
      <div className="back-to-inventory" onClick={() => navigate('/inventory')}>
        <IoReturnUpBack /> Back To Inventory
      </div>
      <ImageCarousel images={images} />
      <div className="car-details-content">
        <div className="left-section">
          <div className="car-detail-title">
              <span className="vehicle-detail-title">{carTitle}</span>
              <span className="carfax-link">
                <a 
                  href={`http://www.carfax.com/VehicleHistory/p/Report.cfx?partner=DVW_1&vin=${carVin}`}
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  <img 
                    src={carfax}
                    width="200" 
                    height="80" 
                    border="0" 
                    alt="Show me the Carfax"
                  />
                </a>
              </span>
            </div>
          <div className="car-details-grid">
            <div className="car-detail">
              <span className="car-detail-label"><IoLogoModelS size={30} style={{ marginRight: '5px'  }} />Model</span>
              <span className="car-detail-value">{car.model}</span>
            </div>
            <div className="car-detail">
              <span className="car-detail-label"><FaCarSide size={30} style={{ marginRight: '5px'  }} />Trim</span>
              <span className="car-detail-value">{car.trim}</span>
            </div>
            <div className="car-detail">
              <span className="car-detail-label"><GiGearStickPattern size={30} style={{ marginRight: '5px'  }} />Trans</span>
              <span className="car-detail-value">{car.engine}</span>
            </div>
            <div className="car-detail">
              <span className="car-detail-label"><BsSpeedometer size={30} style={{ marginRight: '5px'  }} />Mileage</span>
              <span className="car-detail-value">{car.mileage}</span>
            </div>
            <div className="car-detail">
              <span className="car-detail-label"><RiPaintLine size={30} style={{ marginRight: '5px'  }} />Exterior Color</span>
              <span className="car-detail-value">{car.exterior_color}</span>
            </div>
            <div className="car-detail">
              <span className="car-detail-label"><GiCarSeat size={30} style={{ marginRight: '5px'  }} />Interior Color</span>
              <span className="car-detail-value">{car.interior_color}</span>
            </div>
            <div className="car-detail">
              <span className="car-detail-label"><IoMdBarcode size={30} style={{ marginRight: '5px'  }} />VIN</span>
              <span className="car-detail-value">{carVin}</span>
            </div>
            <div className="car-detail">
              <span className="car-detail-label"><MdOutlineAttachMoney size={30} style={{ marginRight: '5px'  }} />Price</span>
              <span className="car-detail-value">${car.price.toLocaleString()}</span>
            </div>
          </div>
          
          <div className='car-equipment'>
            <span className='car-equipment-title'>Vehicle Equipment</span>
            <AccordionGroup size="lg" color='white' variant="solid" transition={{initial: "0.7s ease-out", expanded: "1.5s ease",}}>
              <Accordion>
                <AccordionSummary style={{ fontSize: '20px', fontWeight: 'bold' }}>Equipment</AccordionSummary>
                <AccordionDetails>
                  <div className="equipment-grid">
                    {car.equipment.split(',').map((item, index) => (
                      <div key={index} className="equipment-item">
                        <li>{item.trim()}</li>
                      </div>
                    ))}
                  </div>
                </AccordionDetails>
              </Accordion>
            </AccordionGroup>
          </div>
          <div className='car-description'>
            <span className='car-description-title'>Vehicle Description</span>
            <span className='car-description-content'>{car.description}</span>
          </div>
        </div>
        <div className="right-section">
          <h5>Price: ${car.price}</h5>
          <div className="loan-calculator">
            <h2 className="loan-calculator-title">Loan Calculator</h2>
            <div className="loan-calculator-row">
              <label className="loan-calculator-label"><MdOutlineAttachMoney size={28} />Vehicle Price: </label>
              <span className="loan-calculator-value">${car.price.toLocaleString()}</span>
            </div>
            <div className="loan-calculator-row">
              <label className="loan-calculator-label"><FaArrowAltCircleDown size={20} style={{ marginRight: '5px'  }}/>Down Payment: </label>
              <input
                className="loan-calculator-input"
                type="number"
                value={downPayment}
                onChange={(e) => setDownPayment(parseFloat(e.target.value))}
                placeholder="Enter down payment"
              />
            </div>
            
            <div className="loan-calculator-row">
              <label className="loan-calculator-label"><AiOutlinePercentage size={20} style={{ marginRight: '5px'  }}/>APR</label>
              <input
                className="loan-calculator-input"
                type="number"
                value={apr}
                onChange={(e) => setApr(parseFloat(e.target.value))}
                placeholder="Enter APR"
              />
            </div>
            
            <div className="loan-calculator-row">
              <label className="loan-calculator-label"><SlCalender size={20} style={{ marginRight: '5px'  }}/>Term: </label>
              <input
                className="loan-calculator-input"
                type="number"
                value={term}
                onChange={(e) => setTerm(parseInt(e.target.value))}
                placeholder="Enter term in months"
              />
            </div>
            {monthlyPayment && (
              <div className="loan-calculator-result">
                <h3 className="loan-calculator-result-text">Your estimated payment is:</h3>
                <h1 className="loan-calculator-result-amount">${monthlyPayment}</h1>
                <h3 className="loan-calculator-result-text">per month</h3>
              </div>
            )}
            <button className="loan-calculator-button" onClick={calculateMonthlyPayment}>
                <span>Calculate Monthly Payment</span>
            </button>
            
            
          </div>
          <ContactBox 
            defaultSubject={defaultSubject} 
            defaultMessage={defaultMessage}
          />
        </div>
      </div>
      <div className='similar-cars-container'>
        <h2>Similar Vehicles</h2>
        {similarCars.length > 4 ? (
        <div className="carousel-wrapper">
          <button className="scroll-button left" onClick={scrollLeft}>
            <FaArrowLeft />
          </button>
          <div className="similar-cars-content"ref={carouselRef}>
          {similarCars.map(similarCar => (
            <div className="similar-Card" 
            key={similarCar.id}
            onClick={() => handleCardClick(similarCar.id)}
            style={{ cursor: 'pointer' }}
            >
                <img className="similar-Profile-Pic" src={similarCar.profile_pic} alt={`${similarCar.brand} ${similarCar.model}`} />
                <div className="similar-card-body">
                    <div className='similar-icon-container'>
                        <FaGripLinesVertical className="icon-style"/>
                    </div>
                    <div className='similar-title-container'>
                        <h5 className="similar-card-title">{similarCar.year} {similarCar.brand} {similarCar.model}</h5>
                        <p className="similar-card-price">${similarCar.price}</p>
                    </div>
                </div>
            </div>
          ))}
        </div>
          <button className="scroll-button right" onClick={scrollRight}>
            <FaArrowRight />
          </button>
        </div>
      ) : (
        <div className="similar-cars-content" ref={carouselRef}>
          {similarCars.map(similarCar => (
            <div className="similar-Card" 
            key={similarCar.id}
            onClick={() => handleCardClick(similarCar.id)}
            style={{ cursor: 'pointer' }}
            >
                <img className="similar-Profile-Pic" src={similarCar.profile_pic} alt={`${similarCar.brand} ${similarCar.model}`} />
                <div className="similar-card-body">
                    <div className='similar-icon-container'>
                        <FaGripLinesVertical className="icon-style"/>
                    </div>
                    <div className='similar-title-container'>
                        <h5 className="similar-card-title">{similarCar.year} {similarCar.brand} {similarCar.model}</h5>
                        <p className="similar-card-price">${similarCar.price}</p>
                    </div>
                </div>
            </div>
          ))}
        </div>
      )}
      </div>
      </div>
  );
}

export default CarDetails;