import './contactBox.css';
import emailjs from "emailjs-com";
import React, { useRef } from 'react';

export const ContactBox = ({ defaultFirstName, defaultLastName, defaultEmail, defaultPhone, defaultSubject, defaultMessage }) => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_h3c12et', 'template_1cwab5p', form.current, 'Jxp06y9U5VmEzGA9m')
        .then(
            () => {
                console.log('SUCCESS!');
            },
            (error) => {
                console.log('FAILED...', error.text);
            }
        );
        e.target.reset();
    };

    return (
        <div className="contactBoxContainer">
            <form ref={form} className="contactBox-form" onSubmit={sendEmail}>
                <div className="contactBox-header">
                    <h2>Contact Us</h2>
                </div>
                <div>
                    <label>First Name:</label>
                    <input type="text" name="firstName" defaultValue={defaultFirstName} />
                </div>
                <div>
                    <label>Last Name:</label>
                    <input type="text" name="lastName" defaultValue={defaultLastName} />
                </div>
                <div>
                    <label>Email:</label>
                    <input type="email" name="email" defaultValue={defaultEmail} />
                </div>
                <div>
                    <label>Phone:</label>
                    <input type="tel" name="phone" defaultValue={defaultPhone} />
                </div>
                <div>
                    <label>Subject:</label>
                    <input type="text" name="subject" defaultValue={defaultSubject} />
                </div>
                <div>
                    <label>Message:</label>
                    <textarea name="message" defaultValue={defaultMessage}></textarea>
                </div>
                <button type="submit">Send Feedback</button>
            </form>
        </div>
    );
};

export default ContactBox;
