import React, { useState, useEffect, useRef } from 'react';
import './Contact.css'
import GoogleMapsLink from '../GoogleMapsLink.js';
import mclaren from '../images/mclaren720s.jpg'
import { FaLocationDot } from "react-icons/fa6";
import { FiPhoneCall } from "react-icons/fi";
import { IoLogoWechat } from "react-icons/io5";
import {APIProvider, Map, Marker} from '@vis.gl/react-google-maps';
import emailjs from "emailjs-com"


const Contact = () => {
    const shopAddress = '4501 Stelton Rd, South Plainfield, NJ 07080';
    const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const form = useRef();
    
    // State for form fields
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
    });
    
    // State for errors
    const [errors, setErrors] = useState({});
    
    useEffect(() => {
        window.scrollTo(0, 0); 
    }, []);
    
    // Handle form input change
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    // Validate form fields
    const validate = () => {
        let tempErrors = {};
        if (!formData.firstName) tempErrors.firstName = "First name is required";
        if (!formData.lastName) tempErrors.lastName = "Last name is required";
        if (!formData.email) tempErrors.email = "Email is required";
        if (!formData.phone) tempErrors.phone = "Phone number is required";
        if (!formData.message) tempErrors.message = "Message is required";
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0; // Return true if no errors
    };
    
    const sendEmail = (e) => {
        e.preventDefault();
        
        // Validate the form
        if (validate()) {
            // Send email if valid
            emailjs
            .sendForm('service_h3c12et', 'template_1cwab5p', form.current, 'Jxp06y9U5VmEzGA9m')
            .then(
                () => {
                    console.log('SUCCESS!');
                    // Reset formData to clear form fields
                    setFormData({
                        firstName: '',
                        lastName: '',
                        phone: '',
                        email: '',
                        message: '',
                        consent: false
                    });
                    e.target.reset(); // Reset the form on success
                },
                (error) => {
                    console.log('FAILED...', error.text);
                }
            );
        }
    };

    return(
        <body>
            <div className='contact-title-container'>
                <div className='contact-title'>
                    Contact Us
                </div>
            </div>
            <div className='contact-us-container'>
                <div className='contact-info-section'>
                    <div className='contact-info-left'>
                        <span className='contact-us-title'>HOW TO REACH US</span>
                        <p>
                        If you need help with any aspect of the buying process, please don’t hesitate to ask us. 
                        Our customer service representatives will be happy to assist you in any way. Whether through email, phone or in person, 
                        we’re here to help you get the customer service you deserve.
                        </p>
                        <p className='social'><FiPhoneCall color='black'/> +1 646-509-6261</p>
                        <p className='social'><IoLogoWechat color='black'/> CommonWealth Motor</p>
                        <p className='social'><FaLocationDot color='black'/> <GoogleMapsLink address={shopAddress} /></p>
                    </div>
                    <div className='contact-info-right'>
                        <img id='mclaren' src={mclaren} />
                    </div>
                    
                </div>
                <div className='feedback-section'>
                    <div id='map' className='map-container'>
                        <APIProvider apiKey={API_KEY} onLoad={() => console.log('Maps API has loaded.')}>
                        <Map
                            defaultZoom={15}
                            defaultCenter={{ lat: 40.5587790548494, lng: -74.43771528073515 }}
                        >
                        <Marker
                            position={{ lat: 40.5587790548494, lng: -74.43771528073515 }}
                            label={{
                                text: "Commonwealth Motor",  
                                color: "black",  
                                fontSize: "16px",  
                                fontWeight: "bold" 
                            }}
                        />
                        </Map>
                        </APIProvider>
                    </div>
                    <div className="feedback-container">
                        <form ref={form} className="feedback-form" onSubmit={sendEmail}>
                                <div className="feedback-header">
                                    <h2>Contact Us</h2>
                                </div>
                                <div className="form-row">
                                    <div className="form-group">
                                        <label>First Name:</label>
                                        <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} />
                                        {errors.firstName && <p className="error">{errors.firstName}</p>}
                                    </div>
                                    <div className="form-group">
                                        <label>Last Name:</label>
                                        <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} />
                                        {errors.lastName && <p className="error">{errors.lastName}</p>}
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group">
                                        <label>Email:</label>
                                        <input type="email" name="email" value={formData.email} onChange={handleChange} />
                                        {errors.email && <p className="error">{errors.email}</p>}
                                    </div>
                                    <div className="form-group">
                                        <label>Phone:</label>
                                        <input type="tel" name="phone" value={formData.phone} onChange={handleChange} />
                                        {errors.phone && <p className="error">{errors.phone}</p>}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Message:</label>
                                    <textarea name="message" value={formData.message} onChange={handleChange}></textarea>
                                    {errors.message && <p className="error">{errors.message}</p>}
                                </div>
                                <button type="submit">Send Feedback</button>
                            </form>
                    </div>
                </div>
            </div>
        </body>
    )
}

export default Contact;