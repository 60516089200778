import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import frontpage from './images/frontpage.jpg'
import './Intro.css'
function Intro(){
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const API_URL = process.env.REACT_APP_API_URL;
    const [filters, setFilters] = useState({
        brand: '',
        model: '',
        year: ''
    });
    const [filterOptions, setFilterOptions] = useState({
        brands: [],
        models: [],
        years: []
    });
    useEffect(() => {
        fetchProducts();
        fetchFilterOptions();
    }, []);
    const handleFilterChange = (e) => { 
        setFilters({
            ...filters,
            [e.target.name]: e.target.value
        });
    };
    const fetchProducts = async () => {
        try {
            const response = await axios.get(`${API_URL}/cars/`);
            const productsData = response.data;
            setProducts(productsData);
        } catch (error) {
            console.error('There was an error fetching the car data!', error);
        } finally {
            setLoading(false);
        }
    };
    const handleSearch = () => {
        let filterString = '';
        if (filters.year) {
            filterString += `year=${filters.year};`;
        }
        if (filters.brand) {
            filterString += `brand=${filters.brand};`;
        }
        if (filters.model) {
            filterString += `model=${filters.model};`;
        }
        if (filterString.endsWith(';')) {
            filterString = filterString.slice(0, -1);
        }

        // Navigate to the inventory page and pass the filter string
        navigate(`/inventory?filters=${encodeURIComponent(filterString)}`);
    };
    const fetchFilterOptions = async () => {
        try {
        const response = await axios.get(`${API_URL}/cars/filter_options/`);
        const filterData = response.data;
        const sortedYears = filterData.years.sort((a, b) => b - a);
        const sortedBrands = filterData.brands.sort((a, b) => a.localeCompare(b));

        setFilterOptions({
            years: sortedYears,
            brands: sortedBrands,
            models: filterData.models
        });
        } catch (error) {
            console.error('There was an error fetching the filter options!', error);
        }
    };
    return(
        <div className='intro-container'>
            <img id='intro' src={frontpage}  alt="Front Page"/>
            <div className="home_filter-section">
                        <div className="row">
                            <div className="row-items">
                                <select name="year" value={filters.year} onChange={handleFilterChange} className="home_year-dropdown">
                                    <option value="">Select Year</option>
                                    {filterOptions.years.map(year => (
                                        <option key={year} value={year}>{year}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="row-items">
                                <select name="brand" value={filters.brand} onChange={handleFilterChange} className="home_brand-dropdown">
                                    <option value="">Select Brand</option>
                                    {filterOptions.brands.map(brand => (
                                        <option key={brand} value={brand}>{brand}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="row-items">
                                <select name="model" value={filters.model} onChange={handleFilterChange} className="home_model-dropdown">
                                    <option value="">Select Model</option>
                                    {filterOptions.models.map(model => (
                                        <option key={model} value={model}>{model}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="row-items">
                                <button 
                                    className="primary-btn px-4 w-100 home_search-button" 
                                    type="button" 
                                    onClick={handleSearch}
                                >
                                    Search
                                </button>
                            </div>
                        </div>
                </div>
        </div>
        
        
    );
}

export default Intro