import React, { useState, useRef , useEffect} from 'react';
import emailjs from "emailjs-com"
import './RequestCar.css'

const SellCar = () => {
    const form = useRef();
    const [formData, setFormData] = useState({
        make: '',
        model: '',
        year: '',
        mileage: '',
        priceToSell: '',
        vin: '',
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        comments: ''
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const validate = () => {
        let tempErrors = {};
        if (!formData.firstName) tempErrors.firstName = "First name is required";
        if (!formData.lastName) tempErrors.lastName = "Last name is required";
        if (!formData.email) tempErrors.email = "Email is required";
        if (!formData.phone) tempErrors.phone = "Phone number is required";
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0; // Return true if no errors
    };

    const sendEmail = (e) => {
        e.preventDefault();
        
        // Validate the form
        if (validate()) {
            // Send email if valid
            emailjs
            .sendForm('service_c1dmpfj', 'template_yprpump', form.current, 'tvKvsGnl0lbXm5xNA')
            .then(
                () => {
                    console.log('SUCCESS!');
                    // Reset formData to clear form fields
                    setFormData({
                        make: '',
                        model: '',
                        year: '',
                        mileage: '',
                        priceToSell: '',
                        vin: '',
                        firstName: '',
                        lastName: '',
                        phone: '',
                        email: '',
                        comments: ''
                    });
                    e.target.reset(); 
                },
                (error) => {
                    console.log('FAILED...', error.text);
                }
            );
        }
    };
    useEffect(() => {
        window.scrollTo(0, 0); 
    }, []);
    return(
        <body>
            <div className='finder-title-container'>
                <div className='finder-title'>
                    Sell Us Your Car
                </div>
            </div>
            <div className='car-finder-container'>
                <h2 className='car-finder-title'>
                    Tell us alittle about your car, and we’ll reach back with a great price!
                </h2>
                <form ref={form} onSubmit={sendEmail} className="form-section">
                    <div className="form-row">
                        <div className="form-group">
                            <label>Make *</label>
                            <input type="text" name="make" value={formData.make} onChange={handleChange} required />
                        </div>
                        <div className="form-group">
                            <label>Model *</label>
                            <input type="text" name="model" value={formData.model} onChange={handleChange} required />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group">
                            <label>Year *</label>
                            <input type="text" name="year" value={formData.year} onChange={handleChange} required />
                        </div>
                        <div className="form-group">
                            <label>Car Mileage *</label>
                            <input type="text" name="mileage" value={formData.mileage} onChange={handleChange} required />
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className="form-group">
                            <label>Price You Want To Sell: </label>
                            <input 
                                type="text" 
                                name="priceToSell"  
                                value={formData.priceToSell} 
                                onChange={handleChange} 
                            />
                        </div>
                        <div className="form-group">
                            <label>Vin Number: *</label>
                            <input type="text" name="vin" value={formData.vin} onChange={handleChange} required/>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Tell Us Alittle About Your Car's Condition</label>
                        <textarea name="comments" value={formData.comments} onChange={handleChange} />
                    </div>

                    <h3>Contact Information</h3>
                    <div className="form-row">
                        <div className="form-group">
                            <label>First Name *</label>
                            <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} required />
                            {errors.firstName && <p className="error">{errors.firstName}</p>}
                        </div>
                        <div className="form-group">
                            <label>Last Name *</label>
                            <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} required />
                            {errors.lastName && <p className="error">{errors.lastName}</p>}
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group">
                            <label>Phone Number *</label>
                            <input type="tel" name="phone" value={formData.phone} onChange={handleChange} required />
                            {errors.phone && <p className="error">{errors.phone}</p>}
                        </div>
                        <div className="form-group">
                            <label>Email *</label>
                            <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                            {errors.email && <p className="error">{errors.email}</p>}
                        </div>
                    </div>
                    <button type="submit" className="submit-btn">Submit</button>
                </form>
            </div>
        </body>
    )
}
export default SellCar;